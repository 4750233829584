/* ## Archive Chapter
--------------------------------------------- */

#psam-mapplic {
    .mapplic-container {
        .mapplic-tooltip {
            text-align: center;
            .mapplic-tooltip-thumbnail {
                float: none;
                margin: 0 auto  1rem;
                border-radius: 0 !important;
                width: 100%;
                height: 50%;
                max-height: 200px;
                padding: 2rem 2rem 0;
            }
            .mapplic-tooltip-content {
                margin: 0 auto  1rem;
                line-height: 2;
                .mapplic-tooltip-title {
                    color: $secondary;
                    line-height: 2;
                    margin: 0 auto !important;
                }
            }
            .mapplic-popup-link {
                float: none;
                min-width: auto;
                margin: 0 auto;
            }
        }
        .mapplic-clear-button,
        .mapplic-zoom-buttons {
            right: 0;
            left: auto;
        }
        .mapplic-minimap {
            right: 48px;
            .mapplic-minimap-layer {
                @media only screen and (max-width: map-get($breakpoints, sm)) {
                    display: none !important;
                }
            }
        }
    }
}