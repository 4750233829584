/* ## Single Chapter
--------------------------------------------- */

.psam-chapters-single {
    .site-inner {
        margin: 0 auto;
    }
    .content {
        > * {
            margin: 2rem auto;
            &:first-child {
                margin-top: 0;
            }
        }
        h2 {
            color: $secondary;
            font-size: 3.5rem;
            font-weight: normal;
            text-transform: uppercase;
            margin-bottom: 2rem;
        }
        p {
            margin: 2rem;
        }
        .chapter-logo {
            max-width: 300px;
        }
        .top-outter {
            margin: 0 auto 2rem;
            @include breakpoint(lg) {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
            }
            .top {
                display: flex;
                flex-flow: column nowrap;
                flex-direction: column-reverse;
                @include breakpoint(lg) {
                    flex-flow: row nowrap;
                    flex-direction: row-reverse;
                    justify-content: center;
                    align-items: center;
                    max-width: 80%;
                }
                .wistia-outter {
                    @include breakpoint(lg) {
                        display: flex;
                        flex-flow: column nowrap;
                        flex: 1 40%;
                    }
                }
                .sponsored-outter {
                    margin: 2rem auto;
                    @include breakpoint(lg) {
                        display: flex;
                        flex-flow: column nowrap;
                        flex: 1 60%;
                        margin: 4rem 2rem;
                    }
                    .sponsored-title {
                        text-align: center;
                        margin: 0 auto 2rem;
                        max-width: 80%;
                        @include breakpoint(xs) {
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: center;
                            align-items: center;
                            margin: 2rem auto;
                            h2 {
                                margin: 0 2rem 0 0;
                            }
                        }
                    }
                    .buttons {
                        text-align: center;
                    }
                    .blurb {
                        margin: 2rem 2rem 0;
                    }
                }
            }
            .teeth {
                margin: 0 auto;
                max-width: 90%;
                img {
                    margin: 0 auto;
                }

            }
        }
        .banner-outter {
            img {
                max-width: 100%;
                max-height: 600px;
            }
        }
        .about-outter {
            .about-title {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin: 0 auto;
                padding: 2rem 0;
                border-top: .5rem solid $accent;
                border-bottom: .5rem solid $accent;
                @include breakpoint(xs) {
                    flex-flow: row nowrap;
                    margin: 4rem auto;
                    padding: 4rem 0;
                    max-width: 80%;
                    h2 {
                        margin: 0 2rem 0 0;
                    }
                }
                @include breakpoint(md) {
                    max-width: 50%;
                }
                h2 {
                    line-height: 1;
                }
            }
        }
        .partners-outter {
            .partners-title {
                text-align: center;
                margin-bottom: 2rem;
                h2 {
                    margin: 0 auto;
                    color: $secondary;
                    padding: 2rem 0;
                    border-top: 1rem solid $accent;
                    border-bottom: 1rem solid $accent;
                }
            }
            .partner-logo-banner-outter {
                margin: 0 auto 2rem;
                max-width: 90%;
                img {
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 300px;
                }
            }
            .partner-list {
                margin: 2rem auto;
                max-width: 80%;
                @include breakpoint(md) {
                    display: flex;  
                    flex-flow: row nowrap;
                    justify-content: space-around;
                    align-items: flex-start;
                }
            }
        }
    }
}