/* # Variables
-----------------------------------------------------------------------*/

/**
 * ### Breakpoints
 * 
 * Based on the most common 16:9 screen resolutions.
 * @link https://pacoup.com/2011/06/12/list-of-true-169-resolutions/
 */

$breakpoints: (
	xs: 512px,
	sm: 768px,
	md: 896px,
	lg: 1152px,
	xl: 1280px
);

/* ### Map State and Pin colors */

$state-green: (
	'ok',
	'nm',
	'nv',
	'or',
	'wi',
	'wv'
);

$state-blue: (
	'sd',
	'ca',
	'tx',
	'wa',
	'id',
	'ut',
	'nc'
);

$pin-green: (
	'FOMS',
	'SIOUX',
	'AOMS',
	'PCOMS',
	'PNW',
	'BOIS',
	'MOSA',
	'USA',
	'LOFS',
	'SSA',
	'BFSC',
	'GCOS'
);
$pin-blue: (
	'OKC',
	'RGOS',
	'BOMS',
	'MSOMS',
	'MIOS',
	'HNOS'
);

/* ### Colors */

$primary: #00a750;
$secondary: #00aeef;
$accent: #7dbc42;
$alt-accent: #66b9e1;
$dark: #30353a;
$white: #ffffff;
$light: #f4f5f6;
$grey: rgb(224, 224, 223);

/* ### Front page Stats */

$stat1-out: #0094D9;
$stat1-mid: #00AEEF;
$stat1-in: #71D0F6;
$stat2-out: #00A99A;
$stat2-mid: #0EB89A;
$stat2-in: #8BCC99;
$stat3-out: #71D0F6;
$stat3-mid: #00AEEF;
$stat3-in: #0094D9;
$stat4-out: #5DB243;
$stat4-mid: #72BF44;
$stat4-in: #A6CE39;

/* ### Typography */

$font-heading: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-body:    'Source Sans Pro', Helvetica, Arial, sans-serif;
$normal:  	   400;
$bold:         600;
$bolder:  	   800;

/* ### Other */

$site-radius: 0;
$site-border: 0.1rem solid $light;
$site-color-border: 0.1rem solid $accent;
$site-color-border-focus: 0.1rem solid $alt-accent;

/* ### transition */
$transition-times: (
	normal: .3s,
	fast: .2s,
	xfast: .1s,
	slow: .5s,
	xslow: 1s
);

/* # Mixins
-----------------------------------------------------------------------*/

/* ### Media Queries */

@mixin breakpoint( $breakpoint ) {

    @if map-has-key( $breakpoints, $breakpoint ) {

        @media ( min-width: #{ map-get( $breakpoints, $breakpoint ) } ) {
            @content;
        }

    } @else if type_of( $breakpoint ) == number and unit( $breakpoint ) == px or unit( $breakpoint ) == em or unit( $breakpoint ) == rem {

        @media (min-width: $breakpoint ) {
            @content;
        }

    } @else {

        @warn "No value could be retrieved from `#{$breakpoint}`. "
              + "Please make sure it is defined in `$breakpoints` map, or it is a valid number with supported unit of px, em or rem.";
    }
}

/* ### Clearfix */

@mixin clearfix {
	clear: both;

	&:before,
	&:after {
		clear: both;
		display: block;
		content: '';
	}
}

/* ### Transitions */

@mixin transition($transition-property, $transition-time, $method) {
	-webkit-transition: $transition-property map-get($transition-times,$transition-time) $method;
	-moz-transition: $transition-property map-get($transition-times,$transition-time) $method;
	-ms-transition: $transition-property map-get($transition-times,$transition-time) $method;
	-o-transition: $transition-property map-get($transition-times,$transition-time) $method;
	transition: $transition-property map-get($transition-times,$transition-time) $method;
}
