/* ## Site Footer
--------------------------------------------- */

.site-footer {
	background-color: $white;
	font-size: 1.8rem;
	// taking out footer border due to color background -ms
	// >.footer-widgets {
	// 	border-top: $site-border;
	// 	border-bottom: $site-border;
	// }
	>.wrap {
		position: relative;
		padding: 3rem 0;
		font-size: 1.6rem;
		background: $secondary;
		color: $white;
		width: 100%;
		max-width: none;
		margin: 0;
		.psam-footer {
			display: flex;
			flex-flow: column;
			align-items: center;
			.footer-item {
				margin: 1rem auto;
				&:first-child {
					margin-top: 0;
				}
				&::last-child {
					margin-bottom: 0;
				}
			}			
			a {
				color: $white;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.social-title {
			text-transform: uppercase;
		}
		.social-facebook {
			display: inline-block;
			height: 32px;
			width: 32px;
		}
		.social-facebook svg {
			fill: rgb(59, 89, 152);
			margin: 0;
		} 
	}
	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.widget-area {
		@include breakpoint(sm) {
			margin-bottom: 0;
		}
	}
}


/* ## Footer Widgets
--------------------------------------------- */

.footer-widgets {
	padding: 0;
	background-color: $grey;
	.wrap {
		padding: 3rem 0;
		@include breakpoint(sm) {
			display: flex;
			flex-wrap: wrap;
		}
	}
	.widget-area {
		margin: 0 auto 4rem;
		@include breakpoint(sm) {
			flex: 1;
			margin-left: 2.564102564102564%;
			&:first-of-type {
				margin-left: 0;
			}
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		.widget {
			width: 100%;
			display: block;
			background-color: $grey;
			.textwidget {
                text-align: center;
			}
			.footer-buttons {
				display: flex;
				flex-flow: column wrap;
				@include breakpoint(sm) {
					flex-flow: row wrap;
				}
				a {
					margin-left: auto;
					margin-right: auto;
					@include breakpoint(sm) {
						margin: 0 auto;						
					}
					&:first-child {
						margin-top: 0;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

/* ## Footer Widgets HOME
--------------------------------------------- */
.home .footer-widgets {
	background-color: $white;
	.widget-area .widget {
		background-color: $white;
	}
}