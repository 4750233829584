/* ## Simple Social Icons
--------------------------------------------- */

.simple-social-icons {
	overflow: hidden;
	svg {
		&[class^="social-"],
		&[class*=" social-"] {
			display: inline-block;
			width: 1em;
			height: 1em;
			stroke-width: 0;
			stroke: currentColor;
			fill: currentColor;
		}
	}
	ul {
		margin: -0.5em -0.5em 0 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		li {
			background: none;
			border: none;
			list-style-type: none;
			margin: 0.5em 0.5em 0 0;
			padding: 0;
			a {
				border: none;
				-moz-box-sizing: content-box;
				-webkit-box-sizing: content-box;
				box-sizing: content-box;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				font-weight: normal;
				height: 1em;
				line-height: 1em;
				text-align: center;
				text-decoration: none;
				text-transform: none;
				width: 1em;
			}
		}
		&.aligncenter {
			justify-content: center;
		}
		&.alignleft {
			justify-content: flex-start;
		}
		&.alignright {
			justify-content: flex-end;
		}
	}
	.widgettitle {
		color: $secondary;
		font-size: 1.6em;
		text-align: center
	}
}


/* ## Gravity Forms
--------------------------------------------- */

.gform_heading,
.gform_body,
.gform_footer {
	clear: both;
}

.gform_heading {
	padding-bottom: 1em;
}

.gform_wrapper {
	.gform_fields {
		list-style: none;
		margin: 0 -1rem;
		padding: 0;
		li {
			list-style-type: none;
		}
		.gfield_visibility_hidden {
			display: none;
		}
	}
	.gfield_checkbox,
	.gfield_radio {
		list-style: none;
		padding: 0;
		display: flex;
		flex-flow: column nowrap;
	}
}

.ginput_container {
	span {
		margin-bottom: 1rem;
	}
	input,
	select,
	textarea {
		width: 100%;
	}
	input,
	textarea {
		margin: 0;
	}
}

.gfield_label {
	display: inline-block;
	padding-bottom: 1rem;
	&:empty {
		display: none;
	}
	.left_label & {
		float: left;
	}
	.right_label & {
		float: right;
	}
}

.gfield_required {
	padding-left: 0.1em;
}

.gfield_checkbox,
.gfield_radio {
	li {
		width: 100%;
	}
	input {
		width: auto;
		float: left;
		margin-right: 1rem;
	}
	label {
		float: left;
		line-height: 1;
	}
}

.gsection {
	margin: 1rem 0;
	h2 {
		border-bottom: $site-border;
		padding-bottom: 1rem;
	}
}

.validation_error,
.gf_progressbar_wrapper {
	padding-bottom: 1em;
}

.gf_progressbar {
	background: $light;
}

.gf_progressbar_percentage {
	background: $dark;
	padding: 0.5rem 1rem;
	text-align: right;
}

.gfield_time_hour,
.gfield_time_minute,
.gfield_time_ampm {
	display: inline-block;
}

.gfield_time_hour,
.gfield_time_minute {
	label {
		display: block;
	}
}

.gfield_time_ampm {
	vertical-align: top;
}

.gfield {
	width: 100%;
	list-style-type: none;
	clear: both;
	overflow: auto;
	padding: 0 1rem 1.5rem;
	margin: 0;
	.validation_message {
		margin-top: 1rem;
	}
	li {
		list-style-type: none;
	}
}

.gform_validation_container {
	display: none;
}

.gform_footer {
	.gform_button {
		width: auto;
	}
}

.ginput_full,
.ginput_left,
.ginput_right {
	input,
	select,
	textarea {
		margin-bottom: 0.5rem;
		width: 100%;
	}
	label {
		display: block;
	}
}

.ginput_full {
	display: block;
}

.ginput_left {
	float: left;
	padding-right: 1rem;
}

.ginput_right {
	float: right;
}

.gf_left_half,
.gf_left_third,
.gf_middle_third,
.gf_first_quarter,
.gf_second_quarter {
	clear: none;
	float: left;
	padding-right: 1rem;
	padding-bottom: 1rem;
}

.gf_right_half,
.gf_right_third,
.gf_fourth_quarter {
	clear: none;
	float: right;
	padding-bottom: 1rem;
}

.gf_third_quarter {
	clear: none;
	float: left;
	padding-bottom: 1rem;
}

li {
	.gf_list_2col &,
	.gf_list_3col &,
	.gf_list_4col &,
	.gf_list_5col & {
		float: left;
		padding-bottom: 1rem;
	}
	.gf_list_inline & {
		display: inline-block;
		padding-right: 1rem;
		padding-bottom: 1rem;
	}
}

@include breakpoint(xs) {
	.ginput_container {
		input,
		select,
		textarea {
			&.large {
				width: 100%;
			}
			&.medium {
				width: 66%;
			}
			&.small {
				width: 33%;
			}
		}
	}
	.ginput_left,
	.ginput_right {
		width: 50%;
	}
	label.gfield_label {
		.left_label &,
		.right_label & {
			width: 25%;
		}
	}
	li {
		&.gf_left_half,
		&.gf_right_half,
		.gf_list_2col & {
			width: 50%;
		}
		&.gf_left_third,
		&.gf_right_third,
		.gf_list_3col & {
			width: 33%;
		}
		&.gf_middle_third {
			width: 34%;
		}
		&.gf_first_quarter,
		&.gf_second_quarter,
		&.gf_third_quarter,
		&.gf_fourth_quarter,
		.gf_list_4col & {
			width: 25%;
		}
		.gf_list_5col & {
			width: 20%;
		}
	}
}


/* ## WP Viral Quiz
--------------------------------------------- */

.wpvq {
	margin: 0 auto;
	.wpvq-question {
		.wpvq-question-img{
			max-height: 250px;
		}		
	}
}


/* ## Mapplic
--------------------------------------------- */

#psam-mapplic {
	// States GREEN
	@each $state in $state-green {
		##{$state} {
			fill: $accent !important;
			& * {
				fill: $accent !important;
			}
		}
	} // States BLUE
	@each $state in $state-blue {
		##{$state} {
			fill: $alt-accent !important;
			& * {
				fill: $alt-accent !important;
			}
		}
	}
	// Pin Color
	.mapplic-pin {
		// Pin GREEN
		@each $chapter in $pin-green {
			&[data-location="#{$chapter}"] {
				&.circular.pin-pulse {
					background-image: none;
					background-color: $primary !important;
					&:before {
						border-color: $primary !important;
					}
				}
			}
		}
		// Pin BLUE
		@each $chapter in $pin-blue {
			&[data-location="#{$chapter}"] {
				&.circular.pin-pulse {
					background-image: none;
					background-color: $secondary !important;
					&:before {
						border-color: $secondary !important;
					}
				}
			}
		}
	}
}
