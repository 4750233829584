/* ## Site Header
--------------------------------------------- */

.site-header {
    position: relative;
    width: 100%;
    z-index: 100;
    background-color: $white;
    border-bottom: $site-border;
    >.wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 95%;
        max-width: 1440px;
        @include breakpoint(md) {
            padding: 0;
            flex-wrap: nowrap;
        }
    }
}


/* ## Title Area
--------------------------------------------- */

.title-area {
    margin: 0 auto 0 0;
    position: relative;
    height: 100%;
    display: block;
    flex: 1;
    padding: 0.618em 0;
    max-width: 20rem;
    @include breakpoint(md) {
        padding: 1.382em 0;
        max-width: 30rem;
        min-width: 20rem;
    }
    @include breakpoint(lg) {
        padding: 1.618em 0;
        margin-right: 2rem;
    }
}

.site-title {
    font-size: 2.4rem;
    font-family: $font-heading;
    font-weight: $bold;
    line-height: 1;
    margin-bottom: 0;
    @include breakpoint(md) {
        font-size: 3rem;
    }
    a {
        color: $dark;
        text-decoration: none;
    }
}

.site-description {
    font-size: 1.4rem;
    margin-bottom: 0;
    line-height: 1;
    @include breakpoint(md) {
        text-align: left;
        font-size: 1.6rem;
        line-height: 1.382;
    }
}


/* ## Default Page Template Title Area
--------------------------------------------- */
header.psam-entry-header,
header.psam-archive-chapter-header {
    display: flex;
    align-items: center;
    background-color: $primary;
    border-top: 2rem solid $secondary;
    border-bottom: 2rem solid $secondary;
    h1 {
        color: $white;
        margin: 0 auto;
        padding: 3rem 0;
        max-width: 75%;
        font-weight: $bold;
    }
}

header.psam-blog-header {
    background-color: $primary;
    border-top: 2rem solid $secondary;
    border-bottom: 2rem solid $secondary;
    text-align: center;
    .archive-description {
        margin: 0 auto;
        padding: 3rem 0;
        max-width: 75%;
        background-color: transparent;
        h1 {
            color: $white;
            font-size: 2em;
            font-weight: $bold;
        }
    }
}


.single-chapter .entry-header .entry-title:after {
    content: ' chapter';
}