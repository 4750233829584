/* ## Entries
--------------------------------------------- */

.entry {
    background-color: $white;
    margin: 6rem auto;
    @include breakpoint(lg) {
        margin: 0;
    }
    // padding: 7.5vw;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.entry-content {
    @include clearfix;
    ol,
    ul {
        margin-bottom: 1em;
        margin-left: 4rem;
    }
    ol>li {
        list-style-type: decimal;
    }
    ul>li {
        list-style-type: disc;
    }
    ol ol,
    ul ul {
        margin-bottom: 0;
    }
    li li {
        list-style-type: circle;
    }
    code {
        background-color: $light;
        display: block;
        padding: 1rem 2rem;
        border: $site-border;
        margin-top: 1em;
    }
    >*:last-child {
        margin-bottom: 0;
    }
}

.entry-image-link {
    display: block;
    margin-bottom: 1em;
    img {
        display: block;
    }
}


/* ## Entry Meta
--------------------------------------------- */

p.entry-meta {
    font-size: 1.6rem;
    margin-bottom: 0;
    .entry-header & {
        margin-bottom: 1em;
    }
}

.entry-categories,
.entry-tags {
    display: block;
}

.entry-author-name {
    text-transform: capitalize;
}

.entry-footer {
    border-top: $site-border;
    margin-top: 5vw;
    padding-top: 5vw;
    @include breakpoint(sm) {
        margin-top: 4rem;
        padding-top: 4rem;
    }
}


/* ## Pagination
--------------------------------------------- */

.pagination {
    margin: 5vw 0;
    @include clearfix;
    @include breakpoint(md) {
        margin: 4rem 0 0;
    }
    & * {
        @include transition(all, normal, ease-in-out);
    }
}

.adjacent-entry-pagination {
    margin-bottom: 0;
}

.archive-pagination {
    ul {
        display: flex;
    }
    li {
        margin: 0 0.25em 0 0;
    }
    a {
        background-color: $alt-accent;
        color: $white;
        font-size: 1.6rem;
        padding: 0.75rem 1rem;
        border-radius: $site-radius;
        text-decoration: none;
        display: block;
    }
    a:hover,
    .active a {
        background-color: $accent;
    }
}


/* ## Comments
--------------------------------------------- */

.comment-respond,
.entry-comments,
.entry-pings {
    background-color: #fff;
    font-size: 1.6rem;
    margin-top: 4rem;
    padding: 7.5vw;
    @include breakpoint(sm) {
        padding: 6rem;
    }
}

.comment-respond {
    .form-submit {
        margin-bottom: 0;
    }
}

.comment-list {
    li {
        padding: 4rem 0 0 5vw;
    }
    .depth-1 {
        padding-left: 0;
    }
    .comment-respond {
        padding: 0 0 0 1em;
    }
    >.comment-respond {
        padding: 0;
    }
}

.comment-header {
    margin-bottom: 5vw;
    @include breakpoint(sm) {
        margin-bottom: 2em;
    }
    p {
        margin-bottom: 0;
    }
}

.comment-edit-link {
    padding-left: calc(4.8rem + 1em);
    .entry-pings & {
        padding: 0;
        clear: both;
        display: block;
    }
}

.comment-meta {
    padding-left: calc(4.8rem + 1em);
    word-break: break-all;
    .entry-pings & {
        padding: 0 0 1em;
    }
}

.comment-content {
    clear: both;
    ul>li {
        list-style-type: disc;
    }
}

.comment-respond {
    input[type="email"],
    input[type="text"],
    input[type="url"] {
        @include breakpoint(xl) {
            width: 50%;
        }
        label {
            display: block;
            margin-right: 1rem;
        }
    }
}

.entry-pings .reply {
    display: none;
}

.pingback:last-of-type p {
    margin-bottom: 0;
}

/* ## Front Page Template
--------------------------------------------- */
.home .psam-home {
    header {
        margin: 2rem 0;
        h1 {
            color: $secondary;
            font-size: 3rem;
            padding: 0;
            margin: 0 auto;
            max-width: 90%;
            @include breakpoint(sm) {
                font-size: 4rem;
            }
            @include breakpoint(xl) {
                max-width: 800px;
            }
        }
    }
    h2 {
        color: $white;
        text-transform: uppercase;
        padding: 2rem 2rem 0;
        margin-bottom: 0;
        @include breakpoint(sm) {
            font-size: 3.5rem;
        }
    }
    p {
        margin: 0;
    }
    .content-outter {
        margin: 2rem;
        @include breakpoint(sm) {
            margin: 4rem auto;
            max-width: 80%;
        }
    }
    .map {
        text-align: center;
        margin: 2rem 0;
        background-color: $accent;
        display: block;
        img {
            margin: 2rem auto;
            max-width: 90%;
            @include breakpoint(md) {
                max-width: 50%;
            }
        }
        .psam-chapter-map {
            margin: 2rem auto;
        }        
        .button {
            font-size: 2rem;
            font-weight: bolder;
            margin-top: 0;
            margin-bottom: 2rem;
            min-width: 265px;
        }
    }
    .teeth {
        margin: 2rem;
        img {
            margin: 0 auto;
        }
    }
    .national-stats {
        background-color: $grey;
        .row-outter {
            @include breakpoint(lg) {
                display: flex;
            }
            &:first-child {
                .row {
                    margin-top: 0;
                    @include breakpoint(lg) {
                        margin-top: 6rem;
                    }
                }
            }
            &:last-child {
                .row {
                    margin-bottom: 0;
                    @include breakpoint(lg) {
                        margin-bottom: 6rem;
                    }
                }
            }
            // reverse direction for rows 1 and 3
            &:nth-child(odd) {
                @include breakpoint(lg) {
                    justify-content: flex-start;
                }
                .row {
                    flex-direction: row-reverse;
                    .in {
                        justify-content: flex-end;
                    }
                }
            }
            &:nth-child(even) {
                @include breakpoint(lg) {
                    justify-content: flex-end;
                    .row {
                        .in {
                            justify-content: flex-start;
                            .content-outter {
                                flex-direction: row-reverse;
                            }
                        }
                    }
                }
            }
            // 1 of 4
            &:nth-child(4n-3) {
                // manually setting row width
                .row {
                    @include breakpoint(lg) {
                        max-width: 65%;
                    }
                }
                .out {
                    background-color: $stat1-out;
                    @include breakpoint(lg) {
                        display: flex;
                        justify-content: flex-end;
                        &:after {
                            content: '';
                            margin-right: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-left: 75px solid $stat1-out;
                            border-bottom: 100px solid transparent;
                        }
                    }
                }
                .mid {
                    background-color: $stat1-mid;
                    @include breakpoint(lg) {
                        display: flex;
                        justify-content: flex-end;
                        &:after {
                            content: '';
                            margin-right: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-left: 75px solid $stat1-mid;
                            border-bottom: 100px solid transparent;
                        }
                    }
                }
                .in {
                    background-color: $stat1-in;
                    @include breakpoint(lg) {
                        &:after {
                            content: '';
                            margin-right: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-left: 75px solid $stat1-in;
                            border-bottom: 100px solid transparent;
                        }
                    }
                    // all but first
                    .svg-outter img.child:nth-child(n+2) {
                        opacity: 0.3;
                    }
                }
            }
            // 2 of 4
            &:nth-child(4n-2) {
                .row {
                    @include breakpoint(lg) {
                        max-width: 90%;
                    }
                }
                .out {
                    background-color: $stat2-out;
                    @include breakpoint(lg) {
                        &:before {
                            content: '';
                            margin-left: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-right: 75px solid $stat2-out;
                            border-bottom: 100px solid transparent;
                            display: flex;
                            flex: 1;
                        }
                    }
                }
                .mid {
                    background-color: $stat2-mid;
                    @include breakpoint(lg) {
                        &:before {
                            content: '';
                            margin-left: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-right: 75px solid $stat2-mid;
                            border-bottom: 100px solid transparent;
                            display: flex;
                            flex: 1;
                        }
                    }
                }
                .in {
                    background-color: $stat2-in;
                    @include breakpoint(lg) {
                        &:before {
                            content: '';
                            margin-left: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-right: 75px solid $stat2-in;
                            border-bottom: 100px solid transparent;
                        }
                    }
                    // all but first
                    .svg-outter img.child:nth-child(n+3) {
                        opacity: 0.3;
                    }
                }
            }
            // 3 of 4
            &:nth-child(4n-1) {
                // manually setting row width
                .row {
                    @include breakpoint(lg) {
                        max-width: 75%;
                    }
                }
                .out {
                    background-color: $stat3-out;
                    @include breakpoint(lg) {
                        display: flex;
                        justify-content: flex-end;
                        &:after {
                            content: '';
                            margin-right: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-left: 75px solid $stat3-out;
                            border-bottom: 100px solid transparent;
                        }
                    }
                }
                .mid {
                    background-color: $stat3-mid;
                    @include breakpoint(lg) {
                        display: flex;
                        justify-content: flex-end;
                        &:after {
                            content: '';
                            margin-right: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-left: 75px solid $stat3-mid;
                            border-bottom: 100px solid transparent;
                        }
                    }
                }
                .in {
                    background-color: $stat3-in;
                    @include breakpoint(lg) {
                        display: flex;
                        justify-content: flex-end;
                        &:after {
                            content: '';
                            margin-right: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-left: 75px solid $stat3-in;
                            border-bottom: 100px solid transparent;
                        }
                    }
                }
            }
            // 4 of 4
            &:nth-child(4n) {
                // manually setting row width
                .row {
                    @include breakpoint(lg) {
                        max-width: 75%;
                    }
                }
                .out {
                    background-color: $stat4-out;
                    @include breakpoint(lg) {
                        &:before {
                            content: '';
                            margin-left: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-right: 75px solid $stat4-out;
                            border-bottom: 100px solid transparent;
                            display: flex;
                            flex: 1;
                        }
                    }
                }
                .mid {
                    background-color: $stat4-mid;
                    @include breakpoint(lg) {
                        &:before {
                            content: '';
                            margin-left: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-right: 75px solid $stat4-mid;
                            border-bottom: 100px solid transparent;
                            display: flex;
                            flex: 1;
                        }
                    }
                }
                .in {
                    background-color: $stat4-in;
                    @include breakpoint(lg) {
                        &:before {
                            content: '';
                            margin-left: -75px;
                            width: 0;
                            height: 0;
                            border-top: 100px solid transparent;
                            border-right: 75px solid $stat4-in;
                            border-bottom: 100px solid transparent;
                        }
                    }
                }
            }
            .row {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                margin: 2rem 0;
                @include breakpoint(lg) {
                    margin: 1rem 0;
                    max-width: 75%;
                }
                .out {
                    flex: 1 5%;
                }
                .mid {
                    flex: 1 5%;
                }
                .in {
                    flex: 1 90%;
                    @include breakpoint(lg) {
                        display: flex;
                    }        
                    .content-outter {
                        display: flex;
                        flex-flow: row center center;
                        color: $white;
                        padding: 0.5rem 0;
                        margin: 0 auto;
                        font-weight: $bold;
                        line-height: 1;
                        max-width: 90%;
                        justify-content: center;
                        @include breakpoint(sm) {
                            padding: 1rem 0;
                            max-width: 60%
                        }
                        @include breakpoint(md) {
                            max-width: 50%;
                        }
                        @include breakpoint(lg) {
                            height: 200px;
                            margin: 0;
                            align-items: center;
                            width: 95%;
                            max-width: 100%;
                        }
                        .stat-top {
                            display: block;
                            font-size: 4rem;
                            font-weight: $bolder;
                            @include breakpoint(sm) {
                                font-size: 5rem;   
                            }
                            @include breakpoint(lg) {
                                font-size: 7rem;
                            }
                        }
                        .stat-bottom {
                            text-align: center;
                            @include breakpoint(sm) {
                                font-size: 2.5rem;
                            }
                            @include breakpoint(lg){
                                flex: 1;
                                font-size: 3rem;
                            }
                        }
                        .svg-outter {
                            display: none;
                            @include breakpoint(lg) {
                                display: flex;
                                flex: 1;
                                align-items: center;
                                justify-content: center;
                            }
                            img {
                                max-width: 8rem;
                                max-height: 8rem;
                                &.circle {
                                    margin: 0 1rem;
                                    max-width: 12rem;
                                    max-height: 12rem;
                                }
                                @include breakpoint(xl) {
                                    max-width: 10rem;
                                    max-height: 10rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ## Tips Template
--------------------------------------------- */
.page-template-tips {
    .entry {
        margin: 0;
    }
    .psam-tips {
        article {
            &:nth-child(2n) .tips-title-outer {
                background-color: $alt-accent;
            }
            &:nth-child(2n-1) .tips-title-outer {
                background-color: $accent;
            }
            &:last-child .tips-content {
                margin-bottom: 0;
            }
        }
        .tips-title-outer {
            display: flex;
            h2 {
                display: block;
                color: $white;
                padding: 2rem 0;
                margin: 0 auto;
                text-transform: uppercase;
                font-size: 2.25rem;
            }
        }
        .tips-content-outer {
             padding: 2rem;
        }
    }
}

/* ## Blog
--------------------------------------------- */
.blog.paged {
    .before-psam-blog {
        display: none;
    }
}

/* ## Single Information
--------------------------------------------- */
.psam-information-single {
    .site-inner {
        margin: 2rem;
        @include breakpoint(lg) {
            max-width: 80%;
            margin: 2rem auto;
        }
        .entry {
            margin: 0;
        }
    }
    .top-outter {
        @include breakpoint(md) {
            display: flex;
            flex-flow: row-reverse nowrap;
            justify-content: space-between;
            align-items: center;
            border-bottom: $site-border;
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            .content-outter {
                flex: 1 50%;
                margin: 2rem 2rem 0;
                .information-symptoms {
                    border-bottom: none;
                    margin: 2rem 2rem 0;
                    p {
                        margin: 0;
                    }
                }
            }
            .image-outter {
                flex: 1 50%;
                margin: 2rem 2rem 0;
            }
        }
        @include breakpoint(lg) {
            
        }
        .content-outter {
            .information-title {
                color: $secondary;
                border-bottom: $site-border;
                margin-bottom: 2rem;
            }
            .information-symptoms {
                display: flex;
                flex-flow: column nowrap;
                border-bottom: $site-border;
                margin: 2rem auto;
                h2 {
                    color: $secondary;
                }
            }
        }
        .image-outter {
            margin: 2rem auto;
            img {
                margin: 0 auto;
            }
        }
    }
}

/* ## Archive Information
--------------------------------------------- */
body.psam-information-archive .site-inner img {
    max-width: 500px;
}

/* ## Pledge Page
--------------------------------------------- */
.page-id-90 {
    .pledge-top-outter {
        margin: 2rem auto;
        .logo {
            max-height: 200px;
            max-width: 100%;
            margin: 2rem auto;
        }
        .practice {
            text-align: center;
            color: $primary;
            font-weight: $bold;
        }
    }
}